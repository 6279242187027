import React from "react";
import { pagesRoutes } from "@utils/variables";
import tw from "twin.macro";

import CustomLink from "@components/link";

import ButtonPrimary from "@components/buttons/primary";

type LinkProps = {
  text?: string,
  className?: string,
  path?: string
};

const StyledLink = tw(CustomLink)`inline-block`;

const LinkPrimary = ({ text, className, path = pagesRoutes.contact }: LinkProps) => (
  <StyledLink to={path}>
    <ButtonPrimary className={className} text={text} />
  </StyledLink>
);

export default LinkPrimary;
