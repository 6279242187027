import React from "react";
import { useTranslation } from "react-i18next";
import { up } from "styled-breakpoints";
import tw from "twin.macro";

import Img from "@images/scroll.svg";

import styled from "styled-components";

import { blink } from "@utils/keyframes";

type ButtonProps = {
  text?: string,
  className?: string,
  detail?: 'Arrow' | 'Dots',
};

const Arrow = () => (
  <Img className="w-8 transform -rotate-90" />
);

const Dots = () => (
  <>
    <Dot className="dot" />
    <Dot className="dot" />
    <Dot className="dot" />
  </>
);

const Button = styled.button`
  transition-timing-function: ease-out;
  ${tw`
    font-pangramBold relative inline-flex items-center py-3 pl-25 text-white bg-orange -tracking-013
    rounded-full overflow-hidden transition-colors duration-300 focus:outline-none
  `}
  
  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0px;
    width: 44px;
    height: 44px;
    border-radius: 9999px;
    transition: all .3s;
    margin: 2px 2px;
    background-color: #fdfdfd;
    width: 46px;
    height: 46px;
    transition: 0.3s all;
    transition-delay: 0.1s;
  }
  
  .hover-effect {
    content: attr(data-text);
    transform-origin: -25% 50%;
    transform: rotate3d(0,0,1,45deg);
    transition-timing-function: ease-in;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 25px;
    padding-right: 25px;
    --text-opacity: 1;
    color: #f06140;
    transition-property: transform;
    transition-duration: 300ms;
    transition-delay: 100ms;
  }

  .svg-color {
    stroke: #F06140;
  }
  
  &:hover {
    // ${tw`bg-orangeHover`}
    
    &::before {
      width: calc(100% - 4px);
      transition-delay: 0s;
    }
    
    .hover-effect {
      transform: rotate3d(0,0,1,0deg);
      transition-timing-function: ease-out;
      transition-delay: 0.2s;
    }
  }
  
  ${up('lg')} {
    .svg-color {

      polyline {
        transform: translateY(-3px);
        transition: transform .3s .2s;
      }
    
      line {
        transform: scaleY(0);
        transition: transform .3s .2s;
      }
    }

    &:hover {
      .svg-color {
        polyline {
          transform: translateY(0);
          transition: transform .3s .4s;
        }
      
        line {
          transform: scaleY(1);
          transition: transform .3s .4s;
        }
      }
      
      .dot {
        animation: ${blink} 1.4s both infinite;
        
        &:nth-child(2) {
          animation-delay: .2s;
        }
        
        &:nth-child(3) {
          animation-delay: .4s;
        }
      }
    }
  }
`;

const StyledDetail = tw.span`flex justify-center items-center w-44 h-44 ml-15 mr-2 text-primary z-1`;

const Dot = tw.span`h-4 w-4 mr-3 bg-orange rounded-full last:mr-0 transition-opacity duration-300`;

const ButtonPrimary = ({ text = 'Buttons:Send request', className = '', detail = 'Arrow' }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button className={ className }>
      {t(`${text}`)}
      <StyledDetail>
        {detail === 'Arrow' ? <Arrow /> : <Dots />}
      </StyledDetail>
      <span className="hover-effect">
        {t(`${text}`)}
      </span>
    </Button>
  );
};

export default ButtonPrimary;
